import { useState, useEffect, useRef, LegacyRef, useMemo } from "react";

import { useQuery } from "@apollo/client";
import { RVTask } from "@regal-voice/shared-types";
import { Form, Select, Radio, Tooltip, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useSelector } from "react-redux";

import { RVButton } from "Components/elements/RVButton/RVButton";
import { generateFormItemRequiredRules } from "Components/FormItems/Shared/Validators/InputValidators/InputValidators";
import { AgentsDropdown } from "Components/shared/AgentsDropdown/AgentsDropdown";
import { getTaskOutcomes } from "Pages/agentDesktop/agentInterface/TaskOutcomes/Queries";
import { TaskActionModal } from "Pages/agentDesktop/agentInterface/TaskOutcomes/TaskActionModal";
import { useFlags } from "Services/FeatureFlagService";
import { alphabeticallySortByProperty, getColor } from "Services/HelpersService";
import { renderErrorMessage } from "Services/LoggingService";
import { selectReassignReasons } from "Services/state/brand";
import { TaskOutcome } from "Types/TaskOutcome";

import { CancelType } from "./Hooks/consts";
import { useTaskReassign } from "./Hooks/useTaskReassign";
import { getCancelSource, useTaskReject } from "./Hooks/useTaskReject";

import styles from "./TerminalTaskActions.module.scss";

const tailLayout = {
    wrapperCol: { span: 24 },
};

const RemoveType = {
    Cancel: "Cancel",
    Reassign: "Reassign",
} as const;

export type RemoveTaskFormData = {
    removeType: keyof typeof RemoveType;
    cancelType: CancelType;
    text: string;
    notes: string;
    reassignAgent: string;
    reassignReason: string;
};

const CancelReasonSelection = ({ taskOutcomes }: { taskOutcomes: Array<TaskOutcome> }) => {
    return (
        <>
            <Form.Item
                label="Cancel for this contact..."
                name="cancelType"
                rules={generateFormItemRequiredRules("Cancel type", false, false)}
            >
                <Radio.Group className="selection-radio" size="small">
                    <Radio className={styles.radio} value={CancelType.Task} data-testid="cancel-type-task">
                        This Task Only
                    </Radio>
                    <Radio
                        className={styles.radio}
                        value={CancelType.CampaignTasks}
                        data-testid="cancel-type-task-and-campaigns"
                    >
                        This Task &amp; End Campaigns
                    </Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name="text" label="Cancelation Reason">
                <Select
                    showSearch
                    placeholder="Select a Reason"
                    allowClear
                    size="large"
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    data-testid="select-cancel-reason"
                >
                    {taskOutcomes
                        .filter(({ isTag }) => !isTag)
                        .map(({ text, description }, idx) => (
                            <Select.Option key={idx} value={text}>
                                <Tooltip
                                    title={description || <i style={{ color: getColor("grey4") }}>No description</i>}
                                    placement={"top"}
                                >
                                    {text}
                                </Tooltip>
                            </Select.Option>
                        ))}
                </Select>
            </Form.Item>
            <Form.Item name="notes" label="Notes">
                <TextArea
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    placeholder="Add any notes"
                    data-testid="cancel-notes"
                ></TextArea>
            </Form.Item>
        </>
    );
};

const ReassignReasonSelection = ({
    task,
    reassignReasons,
}: {
    task?: RVTask;
    reassignReasons: Array<{ id: string; reason: string }>;
}) => (
    <>
        <AgentsDropdown data-testid="reassign-agent" task={task} removeAiAgents showOptionTooltip={false} />

        <Form.Item
            name="reassignReason"
            label="Reassign Reason"
            rules={generateFormItemRequiredRules("Reassign Reason", false, false)}
        >
            <Select
                allowClear
                showSearch
                placeholder="Select a Reason"
                size="large"
                getPopupContainer={(trigger: any) => trigger.parentNode}
                data-testid="reassign-reason"
            >
                {reassignReasons.map(({ id, reason }) => (
                    <Select.Option key={id} value={reason}>
                        <Tooltip
                            title={reason || <i style={{ color: getColor("grey4") }}>No description</i>}
                            placement={"top"}
                        >
                            {reason}
                        </Tooltip>
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    </>
);

const MODAL_TITLE = "Remove Task";

export type TerminalTaskActionsProps = {
    task: RVTask | undefined;
    asPopup?: boolean;
    renderTargetClass?: string;
};

export function TerminalTaskActions({ task, asPopup, renderTargetClass }: TerminalTaskActionsProps): JSX.Element {
    const { enableAgentToReassignTask, customCancelTaskEnabled } = useFlags();

    const formRef = useRef<HTMLElement>();
    const [form] = Form.useForm();
    const [showWindow, setShowWindow] = useState<boolean>(false);
    const { handleCancelTask, loading } = useTaskReject({ task });
    const { reassignTask, loading: isUpdating } = useTaskReassign({ task });
    const [taskOutcomes, setTaskOutcomes] = useState<Array<TaskOutcome>>([]);

    const { refetch } = useQuery(getTaskOutcomes, {
        variables: { outcomeType: "cancelation_reason" },
        skip: !showWindow,
        fetchPolicy: "cache-and-network",
        onCompleted({ getTaskOutcomes }) {
            const sortedTaskOutcomes = [...getTaskOutcomes].sort(alphabeticallySortByProperty("text"));
            setTaskOutcomes(sortedTaskOutcomes);
        },
        onError(error) {
            renderErrorMessage({
                error,
                content: "Unable to fetch task cancellation reasons",
                loggerContext: "Unable to fetch task cancellation reasons",
            });
        },
    });

    const reassignReasons = useSelector(selectReassignReasons);
    const removeType = Form.useWatch(["removeType"], form);

    // This is a little strange, but its the only way to get the
    // correct type with the weird conditional logic we have.
    // Probably a better way if i had more time to rethink it all
    const forcedType = useMemo(() => {
        if (removeType) {
            return removeType;
        }
        if (enableAgentToReassignTask && !customCancelTaskEnabled) {
            return RemoveType.Reassign;
        }
        if (!enableAgentToReassignTask && customCancelTaskEnabled) {
            return RemoveType.Cancel;
        }
        return RemoveType.Cancel;
    }, [removeType, enableAgentToReassignTask, customCancelTaskEnabled]);

    useEffect(() => {
        showWindow && refetch();
        // only refetch when showWindow changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showWindow]);

    function onFinish(formValues: RemoveTaskFormData) {
        if (forcedType === RemoveType.Reassign) {
            reassignTask(formValues.reassignAgent, formValues.reassignReason);
        } else {
            handleCancelTask(formValues.cancelType, {
                source: getCancelSource(formValues.cancelType),
                reason: formValues.text,
                notes: formValues.notes,
            });
        }
    }

    const showCancelOption =
        (customCancelTaskEnabled && !enableAgentToReassignTask) || forcedType === RemoveType.Cancel;
    const showReassignOption =
        (enableAgentToReassignTask && !customCancelTaskEnabled) || forcedType === RemoveType.Reassign;
    const showRadioSelection = enableAgentToReassignTask && customCancelTaskEnabled;

    return (
        <div ref={formRef as LegacyRef<HTMLDivElement>}>
            <TaskActionModal
                form={form}
                showWindow={showWindow}
                setShowWindow={setShowWindow}
                modalTitle={MODAL_TITLE}
                targetTooltipTitle={MODAL_TITLE}
                renderTargetIcon="close"
                renderTargetClass={renderTargetClass}
                onFinish={onFinish}
                formRefs={[formRef]}
                initialValues={{
                    removeType: forcedType,
                    cancelType: CancelType.Task,
                }}
                asPopup={asPopup}
            >
                <>
                    {showRadioSelection && (
                        <Form.Item
                            label="Remove Task"
                            name="removeType"
                            rules={generateFormItemRequiredRules("Remove type", false, false)}
                        >
                            <Radio.Group className="selection-radio" size="small">
                                <Radio
                                    className={styles.radio}
                                    value={RemoveType.Cancel}
                                    data-testid="remove-type-cancel"
                                >
                                    Cancel Task
                                </Radio>
                                <Radio
                                    className={styles.radio}
                                    value={RemoveType.Reassign}
                                    data-testid="remove-type-reassign"
                                >
                                    Reassign Task to Agent
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    )}

                    {!showRadioSelection && showCancelOption && (
                        <Typography.Title className={styles.singleTitle} level={5}>
                            Cancel Task
                        </Typography.Title>
                    )}
                    {!showRadioSelection && showReassignOption && (
                        <Typography.Title className={styles.singleTitle} level={5}>
                            Reassign Task
                        </Typography.Title>
                    )}

                    {showCancelOption && <CancelReasonSelection taskOutcomes={taskOutcomes} />}
                    {showReassignOption && <ReassignReasonSelection task={task} reassignReasons={reassignReasons} />}

                    <div className={styles.buttonFix}>
                        <Form.Item {...tailLayout}>
                            <RVButton
                                disabled={loading || isUpdating}
                                loading={loading || isUpdating}
                                block={true}
                                htmlType="submit"
                                size="large"
                                type="primary"
                                data-testid="remove-task-btn"
                                data-dd-action-name={
                                    removeType === RemoveType.Reassign ? "Reassign Task" : "Cancel Task"
                                }
                            >
                                {"Remove Task"}
                            </RVButton>
                        </Form.Item>
                    </div>
                </>
            </TaskActionModal>
        </div>
    );
}
