import { createSelector } from "@reduxjs/toolkit";
import {
    ConferenceParticipant,
    ConferenceParticipantStatus,
    CONFERENCE_PARTICIPANT_STATUS,
    TASK_TYPES,
} from "@regal-voice/shared-types";

import { selectWorkerUri } from "Services/state/agent/AgentInformationSlice";
import { selectActiveConferenceTask } from "Services/state/conferences";
import { isPendingInboundConferenceTask } from "Services/Task.service";

import { RootState } from "../Storage";

import type { RVTask } from "@regal-voice/shared-types";

const DISCONNECTED_STATUSES: ConferenceParticipantStatus[] = ["completed", "no-answer", "busy", "failed", "canceled"];
const ALLOWED_STATUSES: ConferenceParticipantStatus[] = ["in-progress", "ringing"];

export const selectConferenceSnapshot = (state: RootState) => state.conferenceSnapshot;

const particpantFromTask = (activeConferenceTask: RVTask<"voice">): ConferenceParticipant<"contact"> => {
    const joinTime = new Date(activeConferenceTask.reservationAcceptedAt || activeConferenceTask.dateCreated).getTime();
    return {
        callSid: activeConferenceTask.attributes.joinCallSid,
        hold: false,
        joinedAt: joinTime,
        label: activeConferenceTask.attributes.name,
        // types say we dont have a null action, but the server says we do....
        // managerAction: null,
        muted: false,
        phoneNumber: activeConferenceTask.attributes.contactPhone,
        profileId: activeConferenceTask.attributes.profileId,
        status: "ringing",
        taskSid: activeConferenceTask.taskSid,
        type: "contact",
    };
};

export const selectConferenceSnapshotSelf = createSelector(
    selectConferenceSnapshot,
    selectWorkerUri,
    (snapshot, uri) => {
        return snapshot.participants.find(
            (participant) => "callerUri" in participant && participant.callerUri.includes(uri || "")
        ) as ConferenceParticipant<"internal"> | undefined;
    }
);

// this is the incoming participant from before the agent picks up
// the agent isn't getting snapshots because they aren't in the conference yet
// but we need to display who is calling in the task card
export const selectSnapshotFallbackTaskParticipant = createSelector(
    selectActiveConferenceTask,
    (activeConferenceTask): ConferenceParticipant<"contact"> | undefined => {
        if (!!activeConferenceTask) {
            return particpantFromTask(activeConferenceTask as unknown as RVTask<"voice">);
        }
    }
);

/**
 * Participant list after filtering out disconnected participants and managers in listen mode.
 */
export const selectConferenceSnapshotActiveParticipants = createSelector(
    selectConferenceSnapshot,
    selectConferenceSnapshotSelf,
    selectSnapshotFallbackTaskParticipant,
    selectActiveConferenceTask,
    (snapshot, selfParticipant, fallbackParticipant, activeConferenceTask) => {
        const participants = snapshot.participants.filter((participant) => {
            const isSelf =
                selfParticipant && "callerUri" in participant && participant.callerUri === selfParticipant.callerUri;
            const isDisconnected = DISCONNECTED_STATUSES.includes(participant.status);
            const isListeningManager = "managerAction" in participant && participant.managerAction === "listen";

            return !isSelf && "status" in participant && !isDisconnected && !isListeningManager;
        });

        if (fallbackParticipant) {
            const hasNoValidParticipants = participants.length === 0;
            const isDifferentConference =
                snapshot.conferenceFriendlyId !== activeConferenceTask?.attributes.conferenceFriendlyId;

            if (hasNoValidParticipants || isDifferentConference) {
                return [fallbackParticipant];
            }
        }
        return participants;
    }
);

export const selectConferenceSnapshotSingularActiveParticipant = createSelector(
    selectConferenceSnapshotActiveParticipants,
    (participants) => {
        return participants.find((participant) =>
            ALLOWED_STATUSES.includes(participant.status as ConferenceParticipantStatus)
        );
    }
);

/**
 * All of the Barging participants (Managers)
 */
export const selectConferenceSnapshotBarges = createSelector(selectConferenceSnapshot, (snapshot) => {
    return snapshot.participants.filter(
        (participant) => participant.type !== "queue" && participant.managerAction == "barge"
    );
});

/**
 * The main contact of the conference
 */
export const selectConferenceSnapshotContactParticipant = createSelector(
    selectConferenceSnapshotActiveParticipants,
    (activeParticipants) => {
        const contact = activeParticipants.find(
            (participant: ConferenceParticipant<"unknown">) => participant?.type === "contact"
        );
        return contact as ConferenceParticipant<"contact"> | undefined;
    }
);
/**
 * Any and all of the agents
 */

/**
 * Conference State
 */
export const selectSnapShotConferenceSid = createSelector(
    selectConferenceSnapshot,
    (snapshot) => snapshot.conferenceSid
);

export const selectSnapshotConferenceFriendlyID = createSelector(
    selectConferenceSnapshot,
    (snapshot) => snapshot.conferenceFriendlyId
);

export const selectSnapshotConferenceIsConnected = createSelector(
    selectConferenceSnapshotActiveParticipants,
    (participants) => participants.some((p) => p.status === CONFERENCE_PARTICIPANT_STATUS.inProgress)
);

export const selectIsConferenceRecording = createSelector(selectConferenceSnapshot, (snapshot) => snapshot.recording);

export const selectConferenceSnapshotAgentCallSid = createSelector(
    selectConferenceSnapshotSelf,
    (agent) => agent?.callSid
);

export const selectConferenceSnapshotContactCallSid = createSelector(
    selectConferenceSnapshotContactParticipant,
    (contact) => contact?.callSid
);

export const selectConferenceSnapshotIsConnected = createSelector(
    selectConferenceSnapshotActiveParticipants,
    (participants) => participants.some((p) => p.status === CONFERENCE_PARTICIPANT_STATUS.inProgress)
);

export const selectSnapshotIsPowerDialTaskWaiting = createSelector(
    selectActiveConferenceTask,
    (activeConferenceTask) =>
        activeConferenceTask &&
        activeConferenceTask.status === "pending" &&
        activeConferenceTask.attributes.autoAnswer &&
        activeConferenceTask.attributes.taskType !== TASK_TYPES.OUTBOUND_AUTO_ANSWER // "regal" is used for sms
);

const selectSnapshotInboundCallTaskWaiting = createSelector(selectActiveConferenceTask, (activeConferenceTask) => {
    return !!(activeConferenceTask && isPendingInboundConferenceTask(activeConferenceTask));
});

export const selectSnapshotShowPanel = createSelector(
    selectActiveConferenceTask,
    selectSnapshotIsPowerDialTaskWaiting,
    selectSnapshotInboundCallTaskWaiting,
    (activeConferenceTask, inboundCallTaskWaiting, isPowerDialTaskWaiting) => {
        const show = !!(isPowerDialTaskWaiting || inboundCallTaskWaiting || activeConferenceTask?.status == "accepted");

        return show;
    }
);
